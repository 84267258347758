import { AddressDto, GroupWithResults, SearchResultDto } from "shared";

const defaultNaf = "MyNaf";
const defaultAddress: AddressDto = {
  streetNumberAndAddress: "55 rue du Faubourg Saint-Honoré",
  postcode: "75001",
  city: "Paris",
  departmentCode: "75",
};

const groupSearchResultBySlugStub: SearchResultDto[] = [
  {
    rome: "H1204",
    siret: "30613890003613",
    distance_m: 0,
    name: "B'T WIN VILLAGE",
    website: "https://www.decathlon.fr/",
    additionalInformation:
      "Chez DECATHLON, les équipes sont engagées pour le sport. Notre entreprise aime valoriser les initiatives, elle permet à tous de s’investir et d’évoluer. “Jouer collectif” fait partie de notre ADN, nous gagnons ensemble uniquement si nous sommes une équipe unie et engagée. Nous portons un sens fort : Être utile aux gens et à leur planète. Le sport n'a de sens que dans un environnement préservé. Nous développons nos activités sans dégrader nos terrains de jeux en proposant des services liés à l'économie circulaire, en proposant des produits éco-conçus en sourçant des énergies renouvelables... Decathlon est engagé dans l'inclusion et la non-discrimination, et agit ainsi quotidiennement en faveur du handicap, des séniors, de la mixité sociale, de l'égalité entre les femmes et les hommes. Chez Decathlon, nous recrutons avant tout des personnalités. Viens vivre une journée d'immersion et de découverte du métier, le mardi 28 mars!",
    customizedName: "B'T WIN VILLAGE",
    voluntaryToImmersion: true,
    fitForDisabledWorkers: false,
    position: { lon: 3.0858455, lat: 50.6210299 },
    romeLabel: "Design industriel",
    appellations: [
      {
        appellationLabel: "Designer industriel / Designeuse industrielle",
        appellationCode: "14000",
      },
    ],
    naf: "6820B",
    establishmentScore: 0,
    nafLabel: "Location et exploitation de biens immobiliers propres ou loués",
    address: {
      streetNumberAndAddress: "Rue Jean Perrin",
      postcode: "59000",
      city: "Lille",
      departmentCode: "59",
    },
    numberOfEmployeeRange: "2000-4999",
    contactMode: "PHONE",
    locationId: "123",
    updatedAt: new Date().toISOString(),
    createdAt: new Date().toISOString(),
  },
  {
    rome: "G1204",
    siret: "50056940500102",
    distance_m: 0,
    name: "DECATHLON MORLAIX",
    website: "https://www.decathlon.fr/",
    additionalInformation:
      "Chez DECATHLON, les équipes sont engagées pour le sport. Notre entreprise aime valoriser les initiatives, elle permet à tous de s’investir et d’évoluer. “Jouer collectif” fait partie de notre ADN, nous gagnons ensemble uniquement si nous sommes une équipe unie et engagée. Nous portons un sens fort : Être utile aux gens et à leur planète. Le sport n'a de sens que dans un environnement préservé. Nous développons nos activités sans dégrader nos terrains de jeux en proposant des services liés à l'économie circulaire, en proposant des produits éco-conçus en sourçant des énergies renouvelables... Decathlon est engagé dans l'inclusion et la non-discrimination, et agit ainsi quotidiennement en faveur du handicap, des séniors, de la mixité sociale, de l'égalité entre les femmes et les hommes. Chez Decathlon, nous recrutons avant tout des personnalités. Viens vivre une journée d'immersion et de découverte du métier, le mardi 28 mars!",
    customizedName: "DECATHLON MORLAIX",
    voluntaryToImmersion: true,
    fitForDisabledWorkers: false,
    position: { lon: -3.8558397, lat: 48.5777403 },
    romeLabel: "Éducation en activités sportives",
    appellations: [
      {
        appellationLabel: "Conseiller sportif / Conseillère sportive",
        appellationCode: "13480",
      },
    ],
    naf: "4764Z",
    establishmentScore: 0,
    nafLabel: "Commerce de détail d'articles de sport en magasin spécialisé",
    address: {
      streetNumberAndAddress: "",
      postcode: "29600",
      city: "Saint-Martin-des-Champs",
      departmentCode: "29",
    },
    numberOfEmployeeRange: "+10000",
    locationId: "123",
    updatedAt: new Date().toISOString(),
    createdAt: new Date().toISOString(),
  },
  {
    rome: "D1211",
    siret: "50056940500813",
    distance_m: 0,
    name: "DECATHLON France",
    website: "https://www.decathlon.fr/",
    additionalInformation:
      "Chez DECATHLON, les équipes sont engagées pour le sport. Notre entreprise aime valoriser les initiatives, elle permet à tous de s’investir et d’évoluer. “Jouer collectif” fait partie de notre ADN, nous gagnons ensemble uniquement si nous sommes une équipe unie et engagée. Nous portons un sens fort : Être utile aux gens et à leur planète. Le sport n'a de sens que dans un environnement préservé. Nous développons nos activités sans dégrader nos terrains de jeux en proposant des services liés à l'économie circulaire, en proposant des produits éco-conçus en sourçant des énergies renouvelables... Decathlon est engagé dans l'inclusion et la non-discrimination, et agit ainsi quotidiennement en faveur du handicap, des séniors, de la mixité sociale, de l'égalité entre les femmes et les hommes. Chez Decathlon, nous recrutons avant tout des personnalités. Viens vivre une journée d'immersion et de découverte du métier, le mardi 28 mars!",
    customizedName: "DECATHLON France",
    voluntaryToImmersion: true,
    fitForDisabledWorkers: false,
    position: { lon: 2.5984269, lat: 48.7758459 },
    romeLabel: "Vente en articles de sport et loisirs",
    appellations: [
      {
        appellationLabel: "Vendeur / Vendeuse en articles de sport",
        appellationCode: "20552",
      },
    ],
    naf: "4764Z",
    establishmentScore: 0,
    nafLabel: "Commerce de détail d'articles de sport en magasin spécialisé",
    address: {
      streetNumberAndAddress: "Rue de la Louvetière",
      postcode: "77340",
      city: "Pontault-Combault",
      departmentCode: "77",
    },
    numberOfEmployeeRange: "+10000",
    locationId: "123",
    updatedAt: new Date().toISOString(),
    createdAt: new Date().toISOString(),
  },
  {
    rome: "D1211",
    siret: "50056940501209",
    distance_m: 0,
    name: "DECATHLON MACON",
    website: "https://www.decathlon.fr/",
    additionalInformation:
      "Chez DECATHLON, les équipes sont engagées pour le sport. Notre entreprise aime valoriser les initiatives, elle permet à tous de s’investir et d’évoluer. “Jouer collectif” fait partie de notre ADN, nous gagnons ensemble uniquement si nous sommes une équipe unie et engagée. Nous portons un sens fort : Être utile aux gens et à leur planète. Le sport n'a de sens que dans un environnement préservé. Nous développons nos activités sans dégrader nos terrains de jeux en proposant des services liés à l'économie circulaire, en proposant des produits éco-conçus en sourçant des énergies renouvelables... Decathlon est engagé dans l'inclusion et la non-discrimination, et agit ainsi quotidiennement en faveur du handicap, des séniors, de la mixité sociale, de l'égalité entre les femmes et les hommes. Chez Decathlon, nous recrutons avant tout des personnalités. Viens vivre une journée d'immersion et de découverte du métier, le mardi 28 mars!",
    customizedName: "DECATHLON MACON",
    voluntaryToImmersion: true,
    fitForDisabledWorkers: false,
    position: { lon: 4.800488, lat: 46.274576 },
    romeLabel: "Vente en articles de sport et loisirs",
    appellations: [
      {
        appellationLabel: "Vendeur / Vendeuse en articles de sport",
        appellationCode: "20552",
      },
    ],
    naf: "4764Z",
    establishmentScore: 0,
    nafLabel: "Commerce de détail d'articles de sport en magasin spécialisé",
    address: {
      streetNumberAndAddress: "N 6",
      postcode: "71000",
      city: "Varennes-lès-Mâcon",
      departmentCode: "71",
    },
    numberOfEmployeeRange: "+10000",
    locationId: "123",
    updatedAt: new Date().toISOString(),
    createdAt: new Date().toISOString(),
  },
  {
    rome: "D1211",
    siret: "50056940502900",
    distance_m: 0,
    name: "DECATHLON FRANCE",
    website: "https://www.decathlon.fr/",
    additionalInformation:
      "Chez DECATHLON, les équipes sont engagées pour le sport. Notre entreprise aime valoriser les initiatives, elle permet à tous de s’investir et d’évoluer. “Jouer collectif” fait partie de notre ADN, nous gagnons ensemble uniquement si nous sommes une équipe unie et engagée. Nous portons un sens fort : Être utile aux gens et à leur planète. Le sport n'a de sens que dans un environnement préservé. Nous développons nos activités sans dégrader nos terrains de jeux en proposant des services liés à l'économie circulaire, en proposant des produits éco-conçus en sourçant des énergies renouvelables... Decathlon est engagé dans l'inclusion et la non-discrimination, et agit ainsi quotidiennement en faveur du handicap, des séniors, de la mixité sociale, de l'égalité entre les femmes et les hommes. Chez Decathlon, nous recrutons avant tout des personnalités. Viens vivre une journée d'immersion et de découverte du métier, le mardi 28 mars!",
    customizedName: "DECATHLON FRANCE",
    voluntaryToImmersion: true,
    fitForDisabledWorkers: false,
    position: { lon: -1.1882893, lat: 47.3735584 },
    romeLabel: "Vente en articles de sport et loisirs",
    appellations: [
      {
        appellationLabel: "Vendeur / Vendeuse en articles de sport",
        appellationCode: "20552",
      },
    ],
    naf: "4764Z",
    establishmentScore: 0,
    nafLabel: "Commerce de détail d'articles de sport en magasin spécialisé",
    address: {
      streetNumberAndAddress: "Boulevard de la Prairie",
      postcode: "44150",
      city: "Ancenis-Saint-Géréon",
      departmentCode: "44",
    },
    numberOfEmployeeRange: "+10000",
    locationId: "123",
    updatedAt: new Date().toISOString(),
    createdAt: new Date().toISOString(),
  },
  {
    rome: "D1505",
    siret: "50056940502900",
    distance_m: 0,
    name: "DECATHLON FRANCE",
    website: "https://www.decathlon.fr/",
    additionalInformation:
      "Chez DECATHLON, les équipes sont engagées pour le sport. Notre entreprise aime valoriser les initiatives, elle permet à tous de s’investir et d’évoluer. “Jouer collectif” fait partie de notre ADN, nous gagnons ensemble uniquement si nous sommes une équipe unie et engagée. Nous portons un sens fort : Être utile aux gens et à leur planète. Le sport n'a de sens que dans un environnement préservé. Nous développons nos activités sans dégrader nos terrains de jeux en proposant des services liés à l'économie circulaire, en proposant des produits éco-conçus en sourçant des énergies renouvelables... Decathlon est engagé dans l'inclusion et la non-discrimination, et agit ainsi quotidiennement en faveur du handicap, des séniors, de la mixité sociale, de l'égalité entre les femmes et les hommes. Chez Decathlon, nous recrutons avant tout des personnalités. Viens vivre une journée d'immersion et de découverte du métier, le mardi 28 mars!",
    customizedName: "DECATHLON FRANCE",
    voluntaryToImmersion: true,
    fitForDisabledWorkers: false,
    position: { lon: -1.1882893, lat: 47.3735584 },
    romeLabel: "Personnel de caisse",
    appellations: [
      {
        appellationLabel: "Hôte / Hôtesse de caisse services clients",
        appellationCode: "38655",
      },
    ],
    naf: "4764Z",
    establishmentScore: 0,
    nafLabel: "Commerce de détail d'articles de sport en magasin spécialisé",
    address: {
      streetNumberAndAddress: "Boulevard de la Prairie",
      postcode: "44150",
      city: "Ancenis-Saint-Géréon",
      departmentCode: "44",
    },
    numberOfEmployeeRange: "+10000",
    locationId: "123",
    updatedAt: new Date().toISOString(),
    createdAt: new Date().toISOString(),
  },
  {
    rome: "D1211",
    siret: "50056940503247",
    distance_m: 0,
    name: "DECATHLON OLONNE SUR MER",
    website: "https://www.decathlon.fr/",
    additionalInformation:
      "Chez DECATHLON, les équipes sont engagées pour le sport. Notre entreprise aime valoriser les initiatives, elle permet à tous de s’investir et d’évoluer. “Jouer collectif” fait partie de notre ADN, nous gagnons ensemble uniquement si nous sommes une équipe unie et engagée. Nous portons un sens fort : Être utile aux gens et à leur planète. Le sport n'a de sens que dans un environnement préservé. Nous développons nos activités sans dégrader nos terrains de jeux en proposant des services liés à l'économie circulaire, en proposant des produits éco-conçus en sourçant des énergies renouvelables... Decathlon est engagé dans l'inclusion et la non-discrimination, et agit ainsi quotidiennement en faveur du handicap, des séniors, de la mixité sociale, de l'égalité entre les femmes et les hommes. Chez Decathlon, nous recrutons avant tout des personnalités. Viens vivre une journée d'immersion et de découverte du métier, le mardi 28 mars!",
    customizedName: "DECATHLON OLONNE SUR MER",
    voluntaryToImmersion: true,
    fitForDisabledWorkers: false,
    position: { lon: -1.78808797973392, lat: 46.51365235 },
    romeLabel: "Vente en articles de sport et loisirs",
    appellations: [
      {
        appellationLabel: "Vendeur / Vendeuse en articles de sport",
        appellationCode: "20552",
      },
    ],
    naf: "4764Z",
    establishmentScore: 0,
    nafLabel: "Commerce de détail d'articles de sport en magasin spécialisé",
    address: {
      streetNumberAndAddress: "32 Boulevard du Vendée Globe",
      postcode: "85340",
      city: "Les Sables-d'Olonne",
      departmentCode: "85",
    },
    numberOfEmployeeRange: "+10000",
    contactMode: "PHONE",
    locationId: "123",
    updatedAt: new Date().toISOString(),
    createdAt: new Date().toISOString(),
  },
  {
    rome: "D1211",
    siret: "50056940503783",
    distance_m: 0,
    name: "DECATHLON EVRY 2",
    website: "https://www.decathlon.fr/",
    additionalInformation:
      "Chez DECATHLON, les équipes sont engagées pour le sport. Notre entreprise aime valoriser les initiatives, elle permet à tous de s’investir et d’évoluer. “Jouer collectif” fait partie de notre ADN, nous gagnons ensemble uniquement si nous sommes une équipe unie et engagée. Nous portons un sens fort : Être utile aux gens et à leur planète. Le sport n'a de sens que dans un environnement préservé. Nous développons nos activités sans dégrader nos terrains de jeux en proposant des services liés à l'économie circulaire, en proposant des produits éco-conçus en sourçant des énergies renouvelables... Decathlon est engagé dans l'inclusion et la non-discrimination, et agit ainsi quotidiennement en faveur du handicap, des séniors, de la mixité sociale, de l'égalité entre les femmes et les hommes. Chez Decathlon, nous recrutons avant tout des personnalités. Viens vivre une journée d'immersion et de découverte du métier, le mardi 28 mars!",
    customizedName: "DECATHLON EVRY 2",
    voluntaryToImmersion: true,
    fitForDisabledWorkers: false,
    position: { lon: 2.427141, lat: 48.630773 },
    romeLabel: "Vente en articles de sport et loisirs",
    appellations: [
      {
        appellationLabel: "Vendeur / Vendeuse en articles de sport",
        appellationCode: "20552",
      },
    ],
    naf: "4764Z",
    establishmentScore: 0,
    nafLabel: "Commerce de détail d'articles de sport en magasin spécialisé",
    address: {
      streetNumberAndAddress:
        "2 Boulevard de l'Europe - Valéry Giscard d'Estaing",
      postcode: "91000",
      city: "Évry-Courcouronnes",
      departmentCode: "91",
    },
    numberOfEmployeeRange: "+10000",
    locationId: "123",
    createdAt: new Date().toISOString(),
  },
  {
    rome: "N1103",
    siret: "53067522200174",
    distance_m: 0,
    name: "Decathlon Logistique",
    website: "https://www.decathlon.fr/",
    additionalInformation:
      "Viens découvrir les métiers de la logistique ! Rien n'est impossible. Tout sera fait pour te permettre d'intégrer nos équipes même si tu te penses en difficulté.",
    customizedName: "Decathlon Logistique",
    voluntaryToImmersion: true,
    fitForDisabledWorkers: false,
    position: { lon: 7.3079948, lat: 47.8053861 },
    romeLabel: "Magasinage et préparation de commandes",
    appellations: [
      {
        appellationLabel: "Aide-magasinier / Aide-magasinière",
        appellationCode: "10880",
      },
      {
        appellationLabel: "Magasinier / Magasinière",
        appellationCode: "16276",
      },
      {
        appellationLabel: "Magasinier / Magasinière cariste",
        appellationCode: "16277",
      },
      {
        appellationLabel: "Préparateur / Préparatrice de commandes",
        appellationCode: "17993",
      },
    ],
    naf: "5210B",
    establishmentScore: 0,
    nafLabel: "Entreposage et stockage",
    address: {
      streetNumberAndAddress: "Rue du Périgord",
      postcode: "68270",
      city: "Wittenheim",
      departmentCode: "68",
    },
    numberOfEmployeeRange: "2000-4999",
    locationId: "123",
    updatedAt: new Date().toISOString(),
    createdAt: new Date().toISOString(),
  },
];

export const groupWithResultsStub: GroupWithResults = {
  group: {
    name: "Decathlon",
    slug: "decathlon",
    options: {
      heroHeader: {
        title: "Toutes les offres décathlon !",
        description: "Decathlon description",
        logoUrl:
          "https://upload.wikimedia.org/wikipedia/commons/thumb/c/c4/Decathlon_Logo.svg/800px-Decathlon_Logo.svg.png",
        backgroundColor: "#FF0000",
      },
      tintColor: "#0082c3",
    },
  },
  results: groupSearchResultBySlugStub,
};

export const seedSearchResults: SearchResultDto[] = [
  {
    rome: "A0000",
    naf: defaultNaf,
    establishmentScore: 0,
    siret: "12345678901234",
    name: "Super Corp",
    voluntaryToImmersion: true,
    fitForDisabledWorkers: true,
    position: { lat: 48.8666, lon: 2.3333 },
    address: defaultAddress,
    contactMode: "PHONE",
    romeLabel: "Super métier",
    appellations: [
      {
        appellationLabel: "Facteur / Factrice",
        appellationCode: "15044",
      },
      {
        appellationLabel: "Développeur / Développeuse web",
        appellationCode: "14156",
      },
    ],
    nafLabel: "Métallurgie",
    website: "www.corp.com/job",
    additionalInformation:
      "Afin de transmettre le savoir faire vous serez encadré par une équipe professionnelle dirigée par un champion du monde de la pizza, afin de transmettre le savoir faire vous serez encadré par une équipe professionnelle dirigée par un champion du monde de la pizza",
    distance_m: 7984654,
    locationId: "123",
    updatedAt: new Date().toISOString(),
    createdAt: new Date().toISOString(),
  },
  {
    rome: "A0001",
    naf: defaultNaf,
    establishmentScore: 0,
    siret: "12345678901234",
    name: "Mega Corp",
    customizedName: "Mega Corp nom personnalisé",
    voluntaryToImmersion: false,
    position: { lat: 48.8666, lon: 2.3333 },
    address: defaultAddress,
    romeLabel:
      "Méga métier, avec un texte très long pour le décrire, et qui va peut-être aller à la ligne",
    appellations: [
      {
        appellationLabel: "Développeur / Développeuse web",
        appellationCode: "14156",
      },
    ],
    nafLabel: "Accueil et Restauration",
    numberOfEmployeeRange: "11-49",
    website: "www.mega-corp.com/job",
    additionalInformation:
      "Un texte super long qui donne des tonnes d'informations complémentaires sur l'immersion, les métiers proposés, tout ça... ",
    distance_m: 7000,
    locationId: "123",
    createdAt: new Date().toISOString(),
  },
  {
    rome: "A0003",
    naf: defaultNaf,
    establishmentScore: 0,
    siret: "12345678901234",
    name: "Coolitude .Inc",
    customizedName: "Tu peux pas test",
    voluntaryToImmersion: false,
    position: { lat: 48.8666, lon: 2.3333 },
    address: defaultAddress,
    contactMode: "PHONE",
    romeLabel:
      "Méga métier, avec un texte très long pour le décrire, et qui va peut-être aller à la ligne",
    appellations: [
      {
        appellationLabel: "Développeur / Développeuse web",
        appellationCode: "14156",
      },
    ],
    nafLabel: "Accueil et Restauration",
    numberOfEmployeeRange: "200-1000",
    website: "www.coolitude-inc.com/job",
    additionalInformation: `Un texte
     sur plusieurs lignes:
     - tiret 1
     - tiret 2
     - tiret 3
     - 7 de pique bonne journée
     
     Bisous.
     L'entreprise cool avec une description sur plusieurs lignes.`,
    distance_m: 500,
    locationId: "123",
    updatedAt: new Date().toISOString(),
    createdAt: new Date().toISOString(),
  },
  {
    rome: "A0002",
    naf: defaultNaf,
    establishmentScore: 0,
    siret: "99345678901234",
    name: "Hyper Corp",
    customizedName: "Hyper Corp nom personnalisé",
    voluntaryToImmersion: false,
    position: { lat: 48.8666, lon: 2.3333 },
    address: defaultAddress,
    contactMode: "IN_PERSON",
    romeLabel: "Hyper métier",
    appellations: [
      {
        appellationLabel: "Facteur / Factrice",
        appellationCode: "15044",
      },
      {
        appellationLabel: "Développeur / Développeuse web",
        appellationCode: "14156",
      },
    ],
    nafLabel: "",
    distance_m: 100,
    locationId: "123",
    updatedAt: new Date().toISOString(),
    createdAt: new Date().toISOString(),
  },
  {
    rome: "A0003",
    naf: defaultNaf,
    establishmentScore: 0,
    siret: "12341678901235",
    name: "Giga Corp",
    voluntaryToImmersion: false,
    position: { lat: 48.8666, lon: 2.3333 },
    address: defaultAddress,
    contactMode: undefined,
    romeLabel: "Giga métier",
    appellations: [
      {
        appellationLabel: "Développeur / Développeuse web",
        appellationCode: "14156",
      },
    ],
    nafLabel: "",
    website: "https://www.example.com",
    locationId: "123",
    updatedAt: new Date().toISOString(),
    createdAt: new Date().toISOString(),
  },
  {
    naf: "1071C",
    establishmentScore: 0,
    name: "LES GOURMANDS DU PRE",
    rome: "D1102",
    siret: "85363850000019",
    address: {
      city: "LE PRE-SAINT-GERVAIS",
      postcode: "93310",
      departmentCode: "93",
      streetNumberAndAddress: "13 PL SEVERINE",
    },
    website: "",
    nafLabel: "Fabrication de pain et de pâtisserie fraîche",
    position: { lat: 48.882481, lon: 2.409773 },
    romeLabel: "Boulangerie - viennoiserie",
    distance_m: 7086.36203317,
    customizedName: "SASU",
    appellations: [
      {
        appellationLabel: "Boulanger / Boulangère",
        appellationCode: "11573",
      },
    ],
    voluntaryToImmersion: true,
    additionalInformation: "",
    numberOfEmployeeRange: "",
    locationId: "123",
    updatedAt: new Date().toISOString(),
    createdAt: new Date().toISOString(),
  },
  {
    naf: "1071C",
    establishmentScore: 0,
    name: "TRANCHE DE CAKE",
    rome: "D1102",
    siret: "49049275800027",
    customizedName: "Boulangerie avec Custom name",
    address: {
      city: "PARIS 15",
      postcode: "75015",
      departmentCode: "75",
      streetNumberAndAddress: "63 BD PASTEUR",
    },
    website: "",
    nafLabel: "Fabrication de pain et de pâtisserie fraîche",
    position: { lat: 48.841252, lon: 2.314529 },
    romeLabel: "Boulangerie - viennoiserie",
    distance_m: 2002.71463904,
    contactMode: "EMAIL",
    appellations: [
      {
        appellationLabel: "Boulanger-pâtissier / Boulangère-pâtissière",
        appellationCode: "11574",
      },
    ],
    voluntaryToImmersion: true,
    additionalInformation: "Test avec description courte",
    numberOfEmployeeRange: "20-49",
    locationId: "123",
    updatedAt: new Date().toISOString(),
    createdAt: new Date().toISOString(),
  },
  {
    naf: "1071C",
    establishmentScore: 0,
    name: "SAS LORETTE VOUILLE (avec custom name vide)",
    rome: "D1102",
    siret: "87804354600018",
    address: {
      city: "PARIS 15",
      postcode: "75015",
      departmentCode: "75",
      streetNumberAndAddress: "1 RUE DE VOUILLE",
    },
    customizedName: "",
    website: "",
    nafLabel: "Fabrication de pain et de pâtisserie fraîche",
    position: { lat: 48.835483, lon: 2.302884 },
    romeLabel: "Boulangerie - viennoiserie",
    distance_m: 2891.57824012,
    contactMode: "EMAIL",
    appellations: [
      {
        appellationLabel: "Boulanger / Boulangère",
        appellationCode: "11573",
      },
    ],
    voluntaryToImmersion: true,
    additionalInformation: "",
    numberOfEmployeeRange: "6-9",
    locationId: "123",
    updatedAt: new Date().toISOString(),
    createdAt: new Date().toISOString(),
  },
  {
    naf: "1071C",
    establishmentScore: 0,
    name: "LES JARDINS DE DELICE",
    rome: "D1102",
    siret: "53855085600040",
    address: {
      city: "SAINT-DENIS",
      postcode: "93200",
      departmentCode: "93",
      streetNumberAndAddress: "4 RUE AUGUSTE DELAUNE",
    },
    website: "",
    nafLabel: "Fabrication de pain et de pâtisserie fraîche",
    position: { lat: 48.93684, lon: 2.348354 },
    romeLabel: "Boulangerie - viennoiserie",
    distance_m: 8913.7826737,
    contactMode: "EMAIL",
    customizedName: "SARL",
    appellations: [
      {
        appellationLabel: "Boulanger-pâtissier / Boulangère-pâtissière",
        appellationCode: "11574",
      },
    ],
    voluntaryToImmersion: true,
    additionalInformation: "",
    numberOfEmployeeRange: "6-9",
    locationId: "123",
    updatedAt: new Date().toISOString(),
    createdAt: new Date().toISOString(),
  },
  {
    naf: "1071C",
    establishmentScore: 0,
    name: "BELAYAN",
    rome: "D1102",
    siret: "79310870500013",
    address: {
      city: "PARIS 18",
      postcode: "75018",
      departmentCode: "75",
      streetNumberAndAddress: "205 RUE MARCADET",
    },
    website: "",
    nafLabel: "Fabrication de pain et de pâtisserie fraîche",
    position: { lat: 48.891983, lon: 2.332542 },
    romeLabel: "Boulangerie - viennoiserie",
    distance_m: 3792.74103994,
    contactMode: "EMAIL",
    appellations: [
      {
        appellationLabel: "Boulanger-pâtissier / Boulangère-pâtissière",
        appellationCode: "11574",
      },
    ],
    voluntaryToImmersion: true,
    additionalInformation: "",
    numberOfEmployeeRange: "3-5",
    locationId: "123",
    updatedAt: new Date().toISOString(),
    createdAt: new Date().toISOString(),
  },
  {
    naf: "5610C",
    establishmentScore: 0,
    name: "MERCI JEROME LEVALLOIS",
    rome: "D1102",
    siret: "81211017900015",
    address: {
      city: "LEVALLOIS-PERRET",
      postcode: "92300",
      departmentCode: "92",
      streetNumberAndAddress: "5 PL DE LA LIBERATION",
    },
    website: "",
    nafLabel: "Restaurants et services de restauration mobile",
    position: { lat: 48.893532, lon: 2.276841 },
    romeLabel: "Boulangerie - viennoiserie",
    distance_m: 4988.30541237,
    contactMode: "EMAIL",
    customizedName: "SARL",
    appellations: [
      {
        appellationLabel: "Boulanger / Boulangère",
        appellationCode: "11573",
      },
    ],
    voluntaryToImmersion: true,
    additionalInformation: "",
    numberOfEmployeeRange: "3-5",
    locationId: "123",
    updatedAt: new Date().toISOString(),
    createdAt: new Date().toISOString(),
  },
  {
    naf: "1071C",
    establishmentScore: 0,
    name: "FATIMA OUSLIMANE",
    rome: "D1102",
    siret: "53143530300023",
    address: {
      city: "PARIS 19",
      postcode: "75019",
      departmentCode: "75",
      streetNumberAndAddress: "20 RUE DU MAROC",
    },
    website: "",
    nafLabel: "Fabrication de pain et de pâtisserie fraîche",
    position: { lat: 48.886728, lon: 2.368943 },
    romeLabel: "Boulangerie - viennoiserie",
    distance_m: 4738.4779907,
    contactMode: "EMAIL",
    appellations: [
      {
        appellationLabel: "Boulanger / Boulangère",
        appellationCode: "11573",
      },
    ],
    voluntaryToImmersion: true,
    additionalInformation: "",
    numberOfEmployeeRange: "6-9",
    locationId: "123",
    updatedAt: new Date().toISOString(),
    createdAt: new Date().toISOString(),
  },
  {
    naf: "1071C",
    establishmentScore: 0,
    name: "SOCIETE DESGRANGES",
    rome: "D1102",
    siret: "50849936500015",
    address: {
      city: "Paris 12e Arrondissement",
      postcode: "75012",
      departmentCode: "75",
      streetNumberAndAddress: "74 Rue Crozatier",
    },
    website: "",
    nafLabel: "Fabrication de pain et de pâtisserie fraîche",
    position: { lat: 48.850382, lon: 2.379031 },
    romeLabel: "Boulangerie - viennoiserie",
    distance_m: 4431.1329398,
    contactMode: "PHONE",
    appellations: [
      {
        appellationLabel:
          "Boulanger-pâtissier-traiteur / Boulangère-pâtissière-traiteuse",
        appellationCode: "11575",
      },
    ],
    voluntaryToImmersion: true,
    additionalInformation: "",
    numberOfEmployeeRange: "6-9",
    locationId: "123",
    updatedAt: new Date().toISOString(),
    createdAt: new Date().toISOString(),
  },
  {
    naf: "1071C",
    establishmentScore: 0,
    name: "MAILLARD",
    rome: "D1102",
    siret: "80535660700012",
    address: {
      city: "LEVALLOIS-PERRET",
      postcode: "92300",
      departmentCode: "92",
      streetNumberAndAddress: "11 RUE VOLTAIRE",
    },
    website: "",
    nafLabel: "Fabrication de pain et de pâtisserie fraîche",
    position: { lat: 48.890785, lon: 2.28094 },
    romeLabel: "Boulangerie - viennoiserie",
    distance_m: 4561.58112591,
    contactMode: "EMAIL",
    customizedName: "SAS",
    appellations: [
      {
        appellationLabel: "Boulanger-pâtissier / Boulangère-pâtissière",
        appellationCode: "11574",
      },
    ],
    voluntaryToImmersion: true,
    additionalInformation: "",
    numberOfEmployeeRange: "10-19",
    locationId: "123",
    updatedAt: new Date().toISOString(),
    createdAt: new Date().toISOString(),
  },
  {
    naf: "1071C",
    establishmentScore: 0,
    name: "SARL SOPHIA",
    rome: "D1102",
    siret: "84044966400019",
    address: {
      city: "PARIS 19",
      postcode: "75019",
      departmentCode: "75",
      streetNumberAndAddress: "30 AV DE FLANDRE",
    },
    website: "",
    nafLabel: "Fabrication de pain et de pâtisserie fraîche",
    position: { lat: 48.886081, lon: 2.371547 },
    romeLabel: "Boulangerie - viennoiserie",
    distance_m: 4839.42843775,
    contactMode: "EMAIL",
    appellations: [
      {
        appellationLabel: "Boulanger / Boulangère",
        appellationCode: "11573",
      },
    ],
    voluntaryToImmersion: true,
    additionalInformation: "",
    numberOfEmployeeRange: "6-9",
    locationId: "123",
    updatedAt: new Date().toISOString(),
    createdAt: new Date().toISOString(),
  },
  {
    naf: "1071C",
    establishmentScore: 0,
    name: "AU PARADIS DU GOURMAND",
    rome: "D1102",
    siret: "48999132300028",
    address: {
      city: "PARIS 14",
      postcode: "75014",
      departmentCode: "75",
      streetNumberAndAddress: "156 RUE RAYMOND LOSSERAND",
    },
    website: "",
    nafLabel: "Fabrication de pain et de pâtisserie fraîche",
    position: { lat: 48.830587, lon: 2.311338 },
    romeLabel: "Boulangerie - viennoiserie",
    distance_m: 3211.61983088,
    contactMode: "EMAIL",
    appellations: [
      {
        appellationLabel: "Boulanger-pâtissier / Boulangère-pâtissière",
        appellationCode: "11574",
      },
    ],
    voluntaryToImmersion: true,
    additionalInformation: "",
    numberOfEmployeeRange: "10-19",
    locationId: "123",
    updatedAt: new Date().toISOString(),
    createdAt: new Date().toISOString(),
  },
  {
    naf: "1071C",
    establishmentScore: 0,
    name: "FRANCOIS HOHL",
    rome: "D1102",
    siret: "37825294400046",
    address: {
      city: "PARIS 13",
      postcode: "75013",
      departmentCode: "75",
      streetNumberAndAddress: "5 BD DE PORT ROYAL",
    },
    website: "",
    nafLabel: "Fabrication de pain et de pâtisserie fraîche",
    position: { lat: 48.836754, lon: 2.350199 },
    romeLabel: "Boulangerie - viennoiserie",
    distance_m: 3310.42849054,
    contactMode: "EMAIL",
    customizedName: "EI",
    appellations: [
      {
        appellationLabel: "Boulanger-pâtissier / Boulangère-pâtissière",
        appellationCode: "11574",
      },
    ],
    voluntaryToImmersion: true,
    additionalInformation: "",
    numberOfEmployeeRange: "",
    locationId: "123",
    updatedAt: new Date().toISOString(),
    createdAt: new Date().toISOString(),
  },
  {
    naf: "1071C",
    establishmentScore: 0,
    name: "EFB",
    rome: "D1102",
    siret: "89836129000015",
    address: {
      city: "PARIS 13",
      postcode: "75013",
      departmentCode: "75",
      streetNumberAndAddress: "11 RUE JEANNE D ARC",
    },
    website: "",
    nafLabel: "Fabrication de pain et de pâtisserie fraîche",
    position: { lat: 48.82908, lon: 2.369112 },
    romeLabel: "Boulangerie - viennoiserie",
    distance_m: 4895.15434177,
    contactMode: "EMAIL",
    customizedName: "SAS",
    appellations: [
      {
        appellationLabel: "Boulanger-pâtissier / Boulangère-pâtissière",
        appellationCode: "11574",
      },
    ],
    voluntaryToImmersion: true,
    additionalInformation: "",
    numberOfEmployeeRange: "",
    locationId: "123",
    updatedAt: new Date().toISOString(),
    createdAt: new Date().toISOString(),
  },
  {
    naf: "1071C",
    establishmentScore: 0,
    name: "RAINETTE",
    rome: "D1102",
    siret: "53388732900027",
    address: {
      city: "PARIS 13",
      postcode: "75013",
      departmentCode: "75",
      streetNumberAndAddress: "5 BD DE PORT ROYAL",
    },
    website: "",
    nafLabel: "Fabrication de pain et de pâtisserie fraîche",
    position: { lat: 48.836754, lon: 2.350199 },
    romeLabel: "Boulangerie - viennoiserie",
    distance_m: 3310.42849054,
    contactMode: "EMAIL",
    customizedName: "EURL",
    appellations: [
      {
        appellationLabel: "Boulanger-pâtissier / Boulangère-pâtissière",
        appellationCode: "11574",
      },
    ],
    voluntaryToImmersion: true,
    additionalInformation: "",
    numberOfEmployeeRange: "3-5",
    locationId: null,
    updatedAt: new Date().toISOString(),
    createdAt: new Date().toISOString(),
  },
  {
    naf: "1071C",
    establishmentScore: 0,
    name: "SAS ANDRE STEVENS",
    rome: "D1102",
    siret: "83134157300010",
    address: {
      city: "PARIS 3",
      postcode: "75003",
      departmentCode: "75",
      streetNumberAndAddress: "4 RUE DE THORIGNY",
    },
    website: "",
    nafLabel: "Fabrication de pain et de pâtisserie fraîche",
    position: { lat: 48.859365, lon: 2.362415 },
    romeLabel: "Boulangerie - viennoiserie",
    distance_m: 3109.76403522,
    contactMode: "EMAIL",
    appellations: [
      {
        appellationLabel: "Boulanger / Boulangère",
        appellationCode: "11573",
      },
    ],
    voluntaryToImmersion: true,
    additionalInformation: "",
    numberOfEmployeeRange: "",
    locationId: "123",
    updatedAt: new Date().toISOString(),
    createdAt: new Date().toISOString(),
  },
  {
    naf: "1071C",
    establishmentScore: 0,
    name: "BEKKARY",
    rome: "D1102",
    siret: "87978073200027",
    address: {
      city: "PARIS 19",
      postcode: "75019",
      departmentCode: "75",
      streetNumberAndAddress: "71 RUE CURIAL",
    },
    website: "",
    nafLabel: "Fabrication de pain et de pâtisserie fraîche",
    position: { lat: 48.894568, lon: 2.373901 },
    romeLabel: "Boulangerie - viennoiserie",
    distance_m: 5599.19303686,
    contactMode: "EMAIL",
    appellations: [
      {
        appellationLabel: "Boulanger-pâtissier / Boulangère-pâtissière",
        appellationCode: "11574",
      },
    ],
    voluntaryToImmersion: true,
    additionalInformation: "",
    numberOfEmployeeRange: "",
    locationId: "123",
    updatedAt: new Date().toISOString(),
    createdAt: new Date().toISOString(),
  },
  {
    naf: "4724Z",
    establishmentScore: 0,
    name: "BOULANGERIE DU NIL",
    rome: "D1102",
    siret: "81280923400014",
    address: {
      city: "PARIS 2",
      postcode: "75002",
      departmentCode: "75",
      streetNumberAndAddress: "7 RUE DU NIL",
    },
    website: "",
    nafLabel:
      "Commerce de détail de pain, pâtisserie et confiserie en magasin spécialisé",
    position: { lat: 48.867705, lon: 2.347813 },
    romeLabel: "Boulangerie - viennoiserie",
    distance_m: 2261.23388227,
    contactMode: "EMAIL",
    appellations: [
      {
        appellationLabel: "Boulanger / Boulangère",
        appellationCode: "11573",
      },
    ],
    voluntaryToImmersion: true,
    additionalInformation: "",
    numberOfEmployeeRange: "10-19",
    locationId: "123",
    updatedAt: new Date().toISOString(),
    createdAt: new Date().toISOString(),
  },
  {
    naf: "1071C",
    establishmentScore: 0,
    name: "AUX DELICES D'IVRY",
    rome: "D1102",
    siret: "89505033400013",
    address: {
      city: "IVRY-SUR-SEINE",
      postcode: "94200",
      departmentCode: "94",
      streetNumberAndAddress: "6 AV DE LA REPUBLIQUE",
    },
    website: "",
    nafLabel: "Fabrication de pain et de pâtisserie fraîche",
    position: { lat: 48.806875, lon: 2.391982 },
    romeLabel: "Boulangerie - viennoiserie",
    distance_m: 7832.92220994,
    contactMode: "EMAIL",
    customizedName: "SAS",
    appellations: [
      {
        appellationLabel: "Boulanger / Boulangère",
        appellationCode: "11573",
      },
    ],
    voluntaryToImmersion: true,
    additionalInformation: "",
    numberOfEmployeeRange: "",
    locationId: "123",
    updatedAt: new Date().toISOString(),
    createdAt: new Date().toISOString(),
  },
  {
    naf: "1071C",
    establishmentScore: 0,
    name: "KILANI OUNISSI",
    rome: "D1102",
    siret: "50300068900011",
    address: {
      city: "PARIS 11",
      postcode: "75011",
      departmentCode: "75",
      streetNumberAndAddress: "191 RUE DU FBG ST ANTOINE",
    },
    website: "",
    nafLabel: "Fabrication de pain et de pâtisserie fraîche",
    position: { lat: 48.850533, lon: 2.381874 },
    romeLabel: "Boulangerie - viennoiserie",
    distance_m: 4631.76289701,
    contactMode: "EMAIL",
    customizedName: "EI",
    appellations: [
      {
        appellationLabel: "Boulanger / Boulangère",
        appellationCode: "11573",
      },
    ],
    voluntaryToImmersion: true,
    additionalInformation: "",
    numberOfEmployeeRange: "6-9",
    locationId: "123",
    updatedAt: new Date().toISOString(),
    createdAt: new Date().toISOString(),
  },
  {
    naf: "1071C",
    establishmentScore: 0,
    name: "BOULANGERIE DU FAUBOURG",
    rome: "D1102",
    siret: "83801538600017",
    address: {
      city: "PARIS 10",
      postcode: "75010",
      departmentCode: "75",
      streetNumberAndAddress: "101 RUE DU FBG SAINT DENIS",
    },
    website: "",
    nafLabel: "Fabrication de pain et de pâtisserie fraîche",
    position: { lat: 48.874767, lon: 2.355532 },
    romeLabel: "Boulangerie - viennoiserie",
    distance_m: 3146.05292448,
    contactMode: "EMAIL",
    appellations: [
      {
        appellationLabel: "Boulanger-pâtissier / Boulangère-pâtissière",
        appellationCode: "11574",
      },
    ],
    voluntaryToImmersion: true,
    additionalInformation: "",
    numberOfEmployeeRange: "6-9",
    locationId: "123",
    updatedAt: new Date().toISOString(),
    createdAt: new Date().toISOString(),
  },
  {
    naf: "1071C",
    establishmentScore: 0,
    name: "YACINE",
    rome: "D1102",
    siret: "82315238400017",
    address: {
      city: "PARIS 12",
      postcode: "75012",
      departmentCode: "75",
      streetNumberAndAddress: "62 RUE DE REUILLY",
    },
    website: "",
    nafLabel: "Fabrication de pain et de pâtisserie fraîche",
    position: { lat: 48.844659, lon: 2.38957 },
    romeLabel: "Boulangerie - viennoiserie",
    distance_m: 5342.41516288,
    contactMode: "EMAIL",
    appellations: [
      {
        appellationLabel: "Boulanger-pâtissier / Boulangère-pâtissière",
        appellationCode: "11574",
      },
    ],
    voluntaryToImmersion: true,
    additionalInformation: "",
    numberOfEmployeeRange: "20-49",
    locationId: "123",
    updatedAt: new Date().toISOString(),
    createdAt: new Date().toISOString(),
  },
  {
    naf: "1071C",
    establishmentScore: 0,
    name: "SAS EFB WALLONS",
    rome: "D1102",
    siret: "90481565100016",
    address: {
      city: "PARIS 13",
      postcode: "75013",
      departmentCode: "75",
      streetNumberAndAddress: "6 RUE DES WALLONS",
    },
    website: "",
    nafLabel: "Fabrication de pain et de pâtisserie fraîche",
    position: { lat: 48.839025, lon: 2.360504 },
    romeLabel: "Boulangerie - viennoiserie",
    distance_m: 3701.23529781,
    contactMode: "EMAIL",
    customizedName: "SASU",
    appellations: [
      {
        appellationLabel: "Boulanger / Boulangère",
        appellationCode: "11573",
      },
    ],
    voluntaryToImmersion: true,
    additionalInformation: "",
    numberOfEmployeeRange: "",
    locationId: "123",
    updatedAt: new Date().toISOString(),
    createdAt: new Date().toISOString(),
  },
  {
    naf: "1071C",
    establishmentScore: 0,
    name: "LA PREMIERE",
    rome: "D1102",
    siret: "81182056200015",
    address: {
      city: "PARIS 17",
      postcode: "75017",
      departmentCode: "75",
      streetNumberAndAddress: "6 RUE DE LEVIS",
    },
    website: "",
    nafLabel: "Fabrication de pain et de pâtisserie fraîche",
    position: { lat: 48.881703, lon: 2.316179 },
    romeLabel: "Boulangerie - viennoiserie",
    distance_m: 2552.77509246,
    contactMode: "EMAIL",
    customizedName: "SARL",
    appellations: [
      {
        appellationLabel: "Boulanger / Boulangère",
        appellationCode: "11573",
      },
    ],
    voluntaryToImmersion: true,
    additionalInformation: "",
    numberOfEmployeeRange: "10-19",
    locationId: "123",
    updatedAt: new Date().toISOString(),
    createdAt: new Date().toISOString(),
  },
  {
    naf: "1071C",
    establishmentScore: 0,
    name: "LA MAISON KERCK",
    rome: "D1102",
    siret: "81960978500012",
    address: {
      city: "PARIS 11",
      postcode: "75011",
      departmentCode: "75",
      streetNumberAndAddress: "76 RUE SAINT MAUR",
    },
    website: "",
    nafLabel: "Fabrication de pain et de pâtisserie fraîche",
    position: { lat: 48.864057, lon: 2.378567 },
    romeLabel: "Boulangerie - viennoiserie",
    distance_m: 4332.59128374,
    contactMode: "EMAIL",
    customizedName: "SAS",
    appellations: [
      {
        appellationLabel: "Boulanger / Boulangère",
        appellationCode: "11573",
      },
    ],
    voluntaryToImmersion: true,
    additionalInformation: "",
    numberOfEmployeeRange: "6-9",
    locationId: "123",
    updatedAt: new Date().toISOString(),
    createdAt: new Date().toISOString(),
  },
  {
    naf: "1071C",
    establishmentScore: 0,
    name: "BENOIT CASTEL MENILMONTANT",
    rome: "D1102",
    siret: "80207258700024",
    address: {
      city: "PARIS 20",
      postcode: "75020",
      departmentCode: "75",
      streetNumberAndAddress: "150 RUE DE MENILMONTANT",
    },
    website: "",
    nafLabel: "Fabrication de pain et de pâtisserie fraîche",
    position: { lat: 48.870261, lon: 2.3978 },
    romeLabel: "Boulangerie - viennoiserie",
    distance_m: 5843.64091263,
    contactMode: "EMAIL",
    appellations: [
      {
        appellationLabel: "Boulanger / Boulangère",
        appellationCode: "11573",
      },
    ],
    voluntaryToImmersion: true,
    additionalInformation: "",
    numberOfEmployeeRange: "20-49",
    locationId: "123",
    updatedAt: new Date().toISOString(),
    createdAt: new Date().toISOString(),
  },
  {
    naf: "1071C",
    establishmentScore: 0,
    name: "AUX PLAISIRS DE VASCO",
    rome: "D1102",
    siret: "50519430800019",
    address: {
      city: "PARIS 15",
      postcode: "75015",
      departmentCode: "75",
      streetNumberAndAddress: "76 RUE VASCO DE GAMA",
    },
    website: "",
    nafLabel: "Fabrication de pain et de pâtisserie fraîche",
    position: { lat: 48.835289, lon: 2.285284 },
    romeLabel: "Boulangerie - viennoiserie",
    distance_m: 3660.04835307,
    contactMode: "EMAIL",
    appellations: [
      {
        appellationLabel: "Boulanger / Boulangère",
        appellationCode: "11573",
      },
    ],
    voluntaryToImmersion: true,
    additionalInformation: "",
    numberOfEmployeeRange: "6-9",
    locationId: "123",
    updatedAt: new Date().toISOString(),
    createdAt: new Date().toISOString(),
  },
  {
    naf: "1071C",
    establishmentScore: 0,
    name: "SIMON QUILLET",
    rome: "D1102",
    siret: "84952296600019",
    address: {
      city: "SAINT-DENIS",
      postcode: "93200",
      departmentCode: "93",
      streetNumberAndAddress: "30 VLA THIERRY",
    },
    website: "",
    nafLabel: "Fabrication de pain et de pâtisserie fraîche",
    position: { lat: 48.92766, lon: 2.367069 },
    romeLabel: "Boulangerie - viennoiserie",
    distance_m: 8389.31076306,
    contactMode: "EMAIL",
    customizedName: "EI",
    appellations: [
      {
        appellationLabel: "Boulanger / Boulangère",
        appellationCode: "11573",
      },
    ],
    voluntaryToImmersion: true,
    additionalInformation: "",
    numberOfEmployeeRange: "",
    locationId: "123",
    updatedAt: new Date().toISOString(),
    createdAt: new Date().toISOString(),
  },
  {
    naf: "1071C",
    establishmentScore: 0,
    name: "LE GRENIER A PAIN BILLANCOURT",
    rome: "D1102",
    siret: "52772015500029",
    address: {
      city: "BOULOGNE-BILLANCOURT",
      postcode: "92100",
      departmentCode: "92",
      streetNumberAndAddress: "68 BD JEAN JAURES",
    },
    website: "",
    nafLabel: "Fabrication de pain et de pâtisserie fraîche",
    position: { lat: 48.84, lon: 2.239577 },
    romeLabel: "Boulangerie - viennoiserie",
    distance_m: 6267.91772871,
    contactMode: "EMAIL",
    customizedName: "SARL",
    appellations: [
      {
        appellationLabel: "Boulanger-pâtissier / Boulangère-pâtissière",
        appellationCode: "11574",
      },
    ],
    voluntaryToImmersion: true,
    additionalInformation: "",
    numberOfEmployeeRange: "10-19",
    locationId: "123",
    updatedAt: new Date().toISOString(),
    createdAt: new Date().toISOString(),
  },
  {
    naf: "1071C",
    establishmentScore: 0,
    name: "LE DELICES DE MARCEAU",
    rome: "D1102",
    siret: "82014870800010",
    address: {
      city: "MONTREUIL",
      postcode: "93100",
      departmentCode: "93",
      streetNumberAndAddress: "99 RUE MARCEAU",
    },
    website: "",
    nafLabel: "Fabrication de pain et de pâtisserie fraîche",
    position: { lat: 48.850644, lon: 2.426403 },
    romeLabel: "Boulangerie - viennoiserie",
    distance_m: 7858.97858526,
    contactMode: "EMAIL",
    customizedName: "SAS",
    appellations: [
      {
        appellationLabel: "Boulanger-pâtissier / Boulangère-pâtissière",
        appellationCode: "11574",
      },
    ],
    voluntaryToImmersion: true,
    additionalInformation: "",
    numberOfEmployeeRange: "6-9",
    locationId: "123",
    updatedAt: new Date().toISOString(),
    createdAt: new Date().toISOString(),
  },
  {
    naf: "1071C",
    establishmentScore: 0,
    name: "ETINCELLE DE GOURMANDISE",
    rome: "D1102",
    siret: "89005892800015",
    address: {
      city: "CHATILLON",
      postcode: "92320",
      departmentCode: "92",
      streetNumberAndAddress: "5 RUE DE LA MAIRIE",
    },
    website: "",
    nafLabel: "Fabrication de pain et de pâtisserie fraîche",
    position: { lat: 48.800274, lon: 2.289135 },
    romeLabel: "Boulangerie - viennoiserie",
    distance_m: 6902.11262242,
    contactMode: "EMAIL",
    appellations: [
      {
        appellationLabel: "Boulanger / Boulangère",
        appellationCode: "11573",
      },
    ],
    voluntaryToImmersion: true,
    additionalInformation: "",
    numberOfEmployeeRange: "",
    locationId: "123",
    updatedAt: new Date().toISOString(),
    createdAt: new Date().toISOString(),
  },
  {
    naf: "1071C",
    establishmentScore: 0,
    name: "AUX DELICES DE GLACIERE",
    rome: "D1102",
    siret: "81901203000014",
    address: {
      city: "PARIS 13",
      postcode: "75013",
      departmentCode: "75",
      streetNumberAndAddress: "90 BD AUGUSTE BLANQUI",
    },
    website: "",
    nafLabel: "Fabrication de pain et de pâtisserie fraîche",
    position: { lat: 48.830952, lon: 2.345462 },
    romeLabel: "Boulangerie - viennoiserie",
    distance_m: 3624.09145976,
    contactMode: "EMAIL",
    appellations: [
      {
        appellationLabel: "Boulanger-pâtissier / Boulangère-pâtissière",
        appellationCode: "11574",
      },
    ],
    voluntaryToImmersion: true,
    additionalInformation: "",
    numberOfEmployeeRange: "3-5",
    locationId: "123",
    updatedAt: new Date().toISOString(),
    createdAt: new Date().toISOString(),
  },
  {
    naf: "1071C",
    establishmentScore: 0,
    name: "LE GRENIER A PAIN SEVRES",
    rome: "D1102",
    siret: "48396966300028",
    address: {
      city: "SEVRES",
      postcode: "92310",
      departmentCode: "92",
      streetNumberAndAddress: "107 GRANDE RUE",
    },
    website: "",
    nafLabel: "Fabrication de pain et de pâtisserie fraîche",
    position: { lat: 48.82239, lon: 2.206952 },
    romeLabel: "Boulangerie - viennoiserie",
    distance_m: 9240.50942889,
    contactMode: "EMAIL",
    customizedName: "SARL",
    appellations: [
      {
        appellationLabel: "Boulanger-pâtissier / Boulangère-pâtissière",
        appellationCode: "11574",
      },
    ],
    voluntaryToImmersion: true,
    additionalInformation: "",
    numberOfEmployeeRange: "10-19",
    locationId: "123",
    updatedAt: new Date().toISOString(),
    createdAt: new Date().toISOString(),
  },
  {
    naf: "1071C",
    establishmentScore: 0,
    name: "GRANDS MAGASINS DU PAIN",
    rome: "D1102",
    siret: "82256907500028",
    address: {
      city: "CLICHY",
      postcode: "92110",
      departmentCode: "92",
      streetNumberAndAddress: "50 RUE PALLOY",
    },
    website: "",
    nafLabel: "Fabrication de pain et de pâtisserie fraîche",
    position: { lat: 48.903791, lon: 2.311138 },
    romeLabel: "Boulangerie - viennoiserie",
    distance_m: 5035.87465645,
    contactMode: "EMAIL",
    appellations: [
      {
        appellationLabel: "Boulanger / Boulangère",
        appellationCode: "11573",
      },
    ],
    voluntaryToImmersion: true,
    additionalInformation: "",
    numberOfEmployeeRange: "10-19",
    locationId: "123",
    updatedAt: new Date().toISOString(),
    createdAt: new Date().toISOString(),
  },
  {
    naf: "1071C",
    establishmentScore: 0,
    name: "LE GRENIER A PAIN LEVALLOIS",
    rome: "D1102",
    siret: "48241939700021",
    address: {
      city: "LEVALLOIS-PERRET",
      postcode: "92300",
      departmentCode: "92",
      streetNumberAndAddress: "53 RUE DU PDT WILSON",
    },
    website: "",
    nafLabel: "Fabrication de pain et de pâtisserie fraîche",
    position: { lat: 48.892369, lon: 2.289996 },
    romeLabel: "Boulangerie - viennoiserie",
    distance_m: 4326.55122943,
    contactMode: "EMAIL",
    customizedName: "SARL",
    appellations: [
      {
        appellationLabel: "Boulanger / Boulangère",
        appellationCode: "11573",
      },
      {
        appellationLabel: "Boulanger-pâtissier / Boulangère-pâtissière",
        appellationCode: "11574",
      },
    ],
    voluntaryToImmersion: true,
    additionalInformation: "",
    numberOfEmployeeRange: "10-19",
    locationId: "123",
    updatedAt: new Date().toISOString(),
    createdAt: new Date().toISOString(),
  },
  {
    naf: "1071C",
    establishmentScore: 0,
    name: "TRANCHE DE CAKE",
    rome: "D1102",
    siret: "49049275800035",
    address: {
      city: "PARIS 7",
      postcode: "75007",
      departmentCode: "75",
      streetNumberAndAddress: "89 RUE DU BAC",
    },
    website: "",
    nafLabel: "Fabrication de pain et de pâtisserie fraîche",
    position: { lat: 48.853858, lon: 2.323899 },
    romeLabel: "Boulangerie - viennoiserie",
    distance_m: 627.10161148,
    contactMode: "EMAIL",
    appellations: [
      {
        appellationLabel: "Boulanger-pâtissier / Boulangère-pâtissière",
        appellationCode: "11574",
      },
    ],
    voluntaryToImmersion: true,
    additionalInformation: "",
    numberOfEmployeeRange: "20-49",
    locationId: "123",
    updatedAt: new Date().toISOString(),
    createdAt: new Date().toISOString(),
  },
  {
    naf: "1071C",
    establishmentScore: 0,
    name: "S.A.S.U BERTHE",
    rome: "D1102",
    siret: "84049762200018",
    address: {
      city: "PARIS 13",
      postcode: "75013",
      departmentCode: "75",
      streetNumberAndAddress: "56 RUE DU CHEVALERET",
    },
    website: "",
    nafLabel: "Fabrication de pain et de pâtisserie fraîche",
    position: { lat: 48.829482, lon: 2.376013 },
    romeLabel: "Boulangerie - viennoiserie",
    distance_m: 5251.04350313,
    contactMode: "EMAIL",
    customizedName: "SASU",
    appellations: [
      {
        appellationLabel: "Boulanger-pâtissier / Boulangère-pâtissière",
        appellationCode: "11574",
      },
    ],
    voluntaryToImmersion: true,
    additionalInformation: "",
    numberOfEmployeeRange: "10-19",
    locationId: "123",
    updatedAt: new Date().toISOString(),
    createdAt: new Date().toISOString(),
  },
  {
    naf: "1071C",
    establishmentScore: 0,
    name: "POILANE",
    rome: "D1102",
    siret: "32444503000012",
    address: {
      city: "PARIS 6",
      postcode: "75006",
      departmentCode: "75",
      streetNumberAndAddress: "8 RUE DU CHERCHE MIDI",
    },
    website: "",
    nafLabel: "Fabrication de pain et de pâtisserie fraîche",
    position: { lat: 48.851286, lon: 2.32898 },
    romeLabel: "Boulangerie - viennoiserie",
    distance_m: 1070.19516661,
    contactMode: "EMAIL",
    appellations: [
      {
        appellationLabel: "Boulanger / Boulangère",
        appellationCode: "11573",
      },
    ],
    voluntaryToImmersion: true,
    additionalInformation: "",
    numberOfEmployeeRange: "100-199",
    locationId: "123",
    updatedAt: new Date().toISOString(),
    createdAt: new Date().toISOString(),
  },
  {
    naf: "1071C",
    establishmentScore: 0,
    name: "MLG EXPLOITATION",
    rome: "D1102",
    siret: "52007182000010",
    address: {
      city: "BOULOGNE-BILLANCOURT",
      postcode: "92100",
      departmentCode: "92",
      streetNumberAndAddress: "19 CRS DE L'ILE SEGUIN",
    },
    website: "",
    nafLabel: "Fabrication de pain et de pâtisserie fraîche",
    position: { lat: 48.828366, lon: 2.235833 },
    romeLabel: "Boulangerie - viennoiserie",
    distance_m: 7051.66379498,
    contactMode: "EMAIL",
    customizedName: "SAS",
    appellations: [
      {
        appellationLabel: "Boulanger / Boulangère",
        appellationCode: "11573",
      },
    ],
    voluntaryToImmersion: true,
    additionalInformation: "",
    numberOfEmployeeRange: "20-49",
    locationId: "123",
    updatedAt: new Date().toISOString(),
    createdAt: new Date().toISOString(),
  },
  {
    naf: "1071C",
    establishmentScore: 0,
    name: "LA TRADITION GOURMANDE",
    rome: "D1102",
    siret: "75228611200012",
    address: {
      city: "PARIS 19",
      postcode: "75019",
      departmentCode: "75",
      streetNumberAndAddress: "2 RUE EUGENE JUMIN",
    },
    website: "",
    nafLabel: "Fabrication de pain et de pâtisserie fraîche",
    position: { lat: 48.886502, lon: 2.392882 },
    romeLabel: "Boulangerie - viennoiserie",
    distance_m: 6162.94190077,
    contactMode: "EMAIL",
    customizedName: "SAS",
    appellations: [
      {
        appellationLabel: "Boulanger / Boulangère",
        appellationCode: "11573",
      },
    ],
    voluntaryToImmersion: true,
    additionalInformation: "",
    numberOfEmployeeRange: "6-9",
    locationId: "123",
    updatedAt: new Date().toISOString(),
    createdAt: new Date().toISOString(),
  },
  {
    naf: "5610C",
    establishmentScore: 0,
    name: "MERCI JEROME PONT-NEUF",
    rome: "D1102",
    siret: "82467102800028",
    address: {
      city: "PARIS 1",
      postcode: "75001",
      departmentCode: "75",
      streetNumberAndAddress: "12 QUAI DE LA MEGISSERIE",
    },
    website: "",
    nafLabel: "Restaurants et services de restauration mobile",
    position: { lat: 48.858028, lon: 2.344641 },
    romeLabel: "Boulangerie - viennoiserie",
    distance_m: 1807.66129169,
    contactMode: "EMAIL",
    customizedName: "EURL",
    appellations: [
      {
        appellationLabel: "Boulanger / Boulangère",
        appellationCode: "11573",
      },
    ],
    voluntaryToImmersion: true,
    additionalInformation: "",
    numberOfEmployeeRange: "6-9",
    locationId: "123",
    updatedAt: new Date().toISOString(),
    createdAt: new Date().toISOString(),
  },
  {
    naf: "1071C",
    establishmentScore: 0,
    name: "M D M",
    rome: "D1102",
    siret: "80344637600011",
    address: {
      city: "SAINT-OUEN-SUR-SEINE",
      postcode: "93400",
      departmentCode: "93",
      streetNumberAndAddress: "23 RUE DES ROSIERS",
    },
    website: "",
    nafLabel: "Fabrication de pain et de pâtisserie fraîche",
    position: { lat: 48.90674, lon: 2.335218 },
    romeLabel: "Boulangerie - viennoiserie",
    distance_m: 5436.48824298,
    contactMode: "PHONE",
    customizedName: "sas",
    appellations: [
      {
        appellationLabel: "Boulanger-pâtissier / Boulangère-pâtissière",
        appellationCode: "11574",
      },
    ],
    voluntaryToImmersion: true,
    additionalInformation: "",
    numberOfEmployeeRange: "1-2",
    locationId: "123",
    updatedAt: new Date().toISOString(),
    createdAt: new Date().toISOString(),
  },
  {
    naf: "1071C",
    establishmentScore: 0,
    name: "MAISON AURELIE RIBAY",
    rome: "D1102",
    siret: "83518647900019",
    address: {
      city: "PARIS 10",
      postcode: "75010",
      departmentCode: "75",
      streetNumberAndAddress: "36 RUE DE DUNKERQUE",
    },
    website: "",
    nafLabel: "Fabrication de pain et de pâtisserie fraîche",
    position: { lat: 48.881063, lon: 2.350239 },
    romeLabel: "Boulangerie - viennoiserie",
    distance_m: 3314.8542011,
    contactMode: "EMAIL",
    customizedName: "SARL",
    appellations: [
      {
        appellationLabel: "Boulanger / Boulangère",
        appellationCode: "11573",
      },
    ],
    voluntaryToImmersion: true,
    additionalInformation: "",
    numberOfEmployeeRange: "6-9",
    locationId: "123",
    updatedAt: new Date().toISOString(),
    createdAt: new Date().toISOString(),
  },
  {
    naf: "1071C",
    establishmentScore: 0,
    name: "S.A.S LEPARQ JEROME",
    rome: "D1102",
    siret: "82253123200012",
    address: {
      city: "PARIS 15",
      postcode: "75015",
      departmentCode: "75",
      streetNumberAndAddress: "6 RUE DE LOURMEL",
    },
    website: "",
    nafLabel: "Fabrication de pain et de pâtisserie fraîche",
    position: { lat: 48.850323, lon: 2.291965 },
    romeLabel: "Boulangerie - viennoiserie",
    distance_m: 2269.93773842,
    contactMode: "EMAIL",
    customizedName: "SASU",
    appellations: [
      {
        appellationLabel: "Boulanger / Boulangère",
        appellationCode: "11573",
      },
    ],
    voluntaryToImmersion: true,
    additionalInformation: "",
    numberOfEmployeeRange: "10-19",
    locationId: "123",
    updatedAt: new Date().toISOString(),
    createdAt: new Date().toISOString(),
  },
  {
    naf: "1071C",
    establishmentScore: 0,
    name: "MERCI JEROME NEUILLY - TERNES",
    rome: "D1102",
    siret: "79376010900025",
    address: {
      city: "PARIS 8",
      postcode: "75008",
      departmentCode: "75",
      streetNumberAndAddress: "270 RUE DU FAUBOURG SAINT HONORE",
    },
    website: "",
    nafLabel: "Fabrication de pain et de pâtisserie fraîche",
    position: { lat: 48.877713, lon: 2.299032 },
    romeLabel: "Boulangerie - viennoiserie",
    distance_m: 2599.52005756,
    contactMode: "EMAIL",
    customizedName: "EURL",
    appellations: [
      {
        appellationLabel: "Boulanger / Boulangère",
        appellationCode: "11573",
      },
    ],
    voluntaryToImmersion: true,
    additionalInformation: "",
    numberOfEmployeeRange: "1-2",
    locationId: "123",
    updatedAt: new Date().toISOString(),
    createdAt: new Date().toISOString(),
  },
  {
    naf: "1071C",
    establishmentScore: 0,
    name: "FATIMA OUSLIMANE",
    rome: "D1102",
    siret: "53143530300015",
    address: {
      city: "PARIS 10",
      postcode: "75010",
      departmentCode: "75",
      streetNumberAndAddress: "57 RUE DU FBG SAINT DENIS",
    },
    website: "",
    nafLabel: "Fabrication de pain et de pâtisserie fraîche",
    position: { lat: 48.871947, lon: 2.353746 },
    romeLabel: "Boulangerie - viennoiserie",
    distance_m: 2867.69681386,
    contactMode: "EMAIL",
    appellations: [
      {
        appellationLabel: "Boulanger / Boulangère",
        appellationCode: "11573",
      },
    ],
    voluntaryToImmersion: true,
    additionalInformation: "",
    numberOfEmployeeRange: "6-9",
    locationId: "123",
    updatedAt: new Date().toISOString(),
    createdAt: new Date().toISOString(),
  },
  {
    naf: "1071C",
    establishmentScore: 0,
    name: "L'ATELIER DU PAIN",
    rome: "D1102",
    siret: "82184494100012",
    address: {
      city: "PARIS 13",
      postcode: "75013",
      departmentCode: "75",
      streetNumberAndAddress: "112 AV D'ITALIE",
    },
    website: "",
    nafLabel: "Fabrication de pain et de pâtisserie fraîche",
    position: { lat: 48.824251, lon: 2.357576 },
    romeLabel: "Boulangerie - viennoiserie",
    distance_m: 4735.97515873,
    contactMode: "EMAIL",
    customizedName: "SARL",
    appellations: [
      {
        appellationLabel: "Boulanger-pâtissier / Boulangère-pâtissière",
        appellationCode: "11574",
      },
    ],
    voluntaryToImmersion: true,
    additionalInformation: "",
    numberOfEmployeeRange: "1-2",
    locationId: "123",
    updatedAt: new Date().toISOString(),
    createdAt: new Date().toISOString(),
  },
  {
    naf: "1071C",
    establishmentScore: 0,
    name: "MAISON MARNAY",
    rome: "D1102",
    siret: "80748375500011",
    address: {
      city: "PARIS 4",
      postcode: "75004",
      departmentCode: "75",
      streetNumberAndAddress: "24 RUE SAINT MARTIN",
    },
    website: "",
    nafLabel: "Fabrication de pain et de pâtisserie fraîche",
    position: { lat: 48.858721, lon: 2.350191 },
    romeLabel: "Boulangerie - viennoiserie",
    distance_m: 2212.43673473,
    contactMode: "EMAIL",
    customizedName: "SARL",
    appellations: [
      {
        appellationLabel: "Boulanger-pâtissier / Boulangère-pâtissière",
        appellationCode: "11574",
      },
    ],
    voluntaryToImmersion: true,
    additionalInformation: "",
    numberOfEmployeeRange: "10-19",
    locationId: "123",
    updatedAt: new Date().toISOString(),
    createdAt: new Date().toISOString(),
  },
  {
    naf: "1071C",
    establishmentScore: 0,
    name: "MAISON LOUISE",
    rome: "D1102",
    siret: "80889123800012",
    address: {
      city: "LEVALLOIS-PERRET",
      postcode: "92300",
      departmentCode: "92",
      streetNumberAndAddress: "85 RUE LOUISE MICHEL",
    },
    website: "",
    nafLabel: "Fabrication de pain et de pâtisserie fraîche",
    position: { lat: 48.890606, lon: 2.291283 },
    romeLabel: "Boulangerie - viennoiserie",
    distance_m: 4109.8070843,
    contactMode: "EMAIL",
    customizedName: "SARL",
    appellations: [
      {
        appellationLabel: "Boulanger / Boulangère",
        appellationCode: "11573",
      },
    ],
    voluntaryToImmersion: true,
    additionalInformation: "",
    numberOfEmployeeRange: "10-19",
    locationId: "123",
    updatedAt: new Date().toISOString(),
    createdAt: new Date().toISOString(),
  },
  {
    naf: "1071C",
    establishmentScore: 0,
    name: "TAL",
    rome: "D1102",
    siret: "75198013700028",
    address: {
      city: "PARIS 4",
      postcode: "75004",
      departmentCode: "75",
      streetNumberAndAddress: "29 RUE SAINT ANTOINE",
    },
    website: "",
    nafLabel: "Fabrication de pain et de pâtisserie fraîche",
    position: { lat: 48.853661, lon: 2.365227 },
    romeLabel: "Boulangerie - viennoiserie",
    distance_m: 3366.43910556,
    contactMode: "EMAIL",
    appellations: [
      {
        appellationLabel: "Boulanger-pâtissier / Boulangère-pâtissière",
        appellationCode: "11574",
      },
    ],
    voluntaryToImmersion: true,
    additionalInformation: "",
    numberOfEmployeeRange: "10-19",
    locationId: "123",
    updatedAt: new Date().toISOString(),
    createdAt: new Date().toISOString(),
  },
  {
    naf: "4724Z",
    establishmentScore: 0,
    name: "BOULANGERIE DU NIL",
    rome: "D1102",
    siret: "81280923400063",
    address: {
      city: "PARIS 4",
      postcode: "75004",
      departmentCode: "75",
      streetNumberAndAddress: "6 COUR DE L'ILE-LOUVIERS",
    },
    website: "",
    nafLabel:
      "Commerce de détail de pain, pâtisserie et confiserie en magasin spécialisé",
    position: { lat: 48.849409, lon: 2.362341 },
    romeLabel: "Boulangerie - viennoiserie",
    distance_m: 3278.35339437,
    contactMode: "EMAIL",
    appellations: [
      {
        appellationLabel: "Boulanger / Boulangère",
        appellationCode: "11573",
      },
    ],
    voluntaryToImmersion: true,
    additionalInformation: "",
    numberOfEmployeeRange: "10-19",
    locationId: "123",
    updatedAt: new Date().toISOString(),
    createdAt: new Date().toISOString(),
  },
  {
    naf: "1071C",
    establishmentScore: 0,
    name: "AU BON PAIN",
    rome: "D1102",
    siret: "52422448200033",
    address: {
      city: "AUBERVILLIERS",
      postcode: "93300",
      departmentCode: "93",
      streetNumberAndAddress: "74 RUE DU LANDY",
    },
    website: "",
    nafLabel: "Fabrication de pain et de pâtisserie fraîche",
    position: { lat: 48.915436, lon: 2.36695 },
    romeLabel: "Boulangerie - viennoiserie",
    distance_m: 7167.8706682,
    contactMode: "EMAIL",
    appellations: [
      {
        appellationLabel: "Boulanger-pâtissier / Boulangère-pâtissière",
        appellationCode: "11574",
      },
    ],
    voluntaryToImmersion: true,
    additionalInformation: "",
    numberOfEmployeeRange: "10-19",
    locationId: "123",
    updatedAt: new Date().toISOString(),
    createdAt: new Date().toISOString(),
  },
  {
    naf: "1071C",
    establishmentScore: 0,
    name: "LA FABRIQUE",
    rome: "D1102",
    siret: "84832695500020",
    address: {
      city: "LEVALLOIS-PERRET",
      postcode: "92300",
      departmentCode: "92",
      streetNumberAndAddress: "21 RUE CARNOT",
    },
    website: "",
    nafLabel: "Fabrication de pain et de pâtisserie fraîche",
    position: { lat: 48.889721, lon: 2.288461 },
    romeLabel: "Boulangerie - viennoiserie",
    distance_m: 4137.90668944,
    contactMode: "EMAIL",
    customizedName: "SAS",
    appellations: [
      {
        appellationLabel: "Boulanger-pâtissier / Boulangère-pâtissière",
        appellationCode: "11574",
      },
    ],
    voluntaryToImmersion: true,
    additionalInformation: "",
    numberOfEmployeeRange: "",
    locationId: "123",
    updatedAt: new Date().toISOString(),
    createdAt: new Date().toISOString(),
  },
  {
    naf: "1071C",
    establishmentScore: 0,
    name: "MAISON LE SURFIN",
    rome: "D1102",
    siret: "89485321700012",
    address: {
      city: "PARIS 14",
      postcode: "75014",
      departmentCode: "75",
      streetNumberAndAddress: "27 RUE HIPPOLYTE MAINDRON",
    },
    website: "",
    nafLabel: "Fabrication de pain et de pâtisserie fraîche",
    position: { lat: 48.831634, lon: 2.321949 },
    romeLabel: "Boulangerie - viennoiserie",
    distance_m: 3034.2509751,
    contactMode: "EMAIL",
    appellations: [
      {
        appellationLabel:
          "Boulanger-pâtissier-traiteur / Boulangère-pâtissière-traiteuse",
        appellationCode: "11575",
      },
    ],
    voluntaryToImmersion: true,
    additionalInformation: "",
    numberOfEmployeeRange: "",
    locationId: "123",
    updatedAt: new Date().toISOString(),
    createdAt: new Date().toISOString(),
  },
  {
    naf: "4724Z",
    establishmentScore: 0,
    name: "BOULANGERIE DU NIL",
    rome: "D1102",
    siret: "81280923400030",
    address: {
      city: "PARIS 11",
      postcode: "75011",
      departmentCode: "75",
      streetNumberAndAddress: "8 RUE PAUL BERT",
    },
    website: "",
    nafLabel:
      "Commerce de détail de pain, pâtisserie et confiserie en magasin spécialisé",
    position: { lat: 48.851476, lon: 2.384658 },
    romeLabel: "Boulangerie - viennoiserie",
    distance_m: 4812.97222357,
    contactMode: "EMAIL",
    appellations: [
      {
        appellationLabel: "Boulanger / Boulangère",
        appellationCode: "11573",
      },
    ],
    voluntaryToImmersion: true,
    additionalInformation: "",
    numberOfEmployeeRange: "10-19",
    locationId: "123",
    updatedAt: new Date().toISOString(),
    createdAt: new Date().toISOString(),
  },
  {
    naf: "1071C",
    establishmentScore: 0,
    name: "LAPIERRE'S ET FILLE",
    rome: "D1102",
    siret: "83427852500029",
    address: {
      city: "NANTERRE",
      postcode: "92000",
      departmentCode: "92",
      streetNumberAndAddress: "28 RUE HENRI BARBUSSE",
    },
    website: "",
    nafLabel: "Fabrication de pain et de pâtisserie fraîche",
    position: { lat: 48.89028, lon: 2.195505 },
    romeLabel: "Boulangerie - viennoiserie",
    distance_m: 9779.62495309,
    contactMode: "EMAIL",
    customizedName: "SARL",
    appellations: [
      {
        appellationLabel: "Boulanger / Boulangère",
        appellationCode: "11573",
      },
    ],
    voluntaryToImmersion: true,
    additionalInformation: "",
    numberOfEmployeeRange: "10-19",
    locationId: "123",
    updatedAt: new Date().toISOString(),
    createdAt: new Date().toISOString(),
  },
  {
    naf: "1071C",
    establishmentScore: 0,
    name: "LE MOULIN DE LA CROIX NIVERT",
    rome: "D1102",
    siret: "80838011700016",
    address: {
      city: "PARIS 15",
      postcode: "75015",
      departmentCode: "75",
      streetNumberAndAddress: "39 RUE DE LA CROIX NIVERT",
    },
    website: "",
    nafLabel: "Fabrication de pain et de pâtisserie fraîche",
    position: { lat: 48.845996, lon: 2.298911 },
    romeLabel: "Boulangerie - viennoiserie",
    distance_m: 2112.0127168,
    contactMode: "EMAIL",
    appellations: [
      {
        appellationLabel: "Boulanger-pâtissier / Boulangère-pâtissière",
        appellationCode: "11574",
      },
    ],
    voluntaryToImmersion: true,
    additionalInformation: "",
    numberOfEmployeeRange: "10-19",
    locationId: "123",
    updatedAt: new Date().toISOString(),
    createdAt: new Date().toISOString(),
  },
  {
    naf: "1071C",
    establishmentScore: 0,
    name: "AU COIN DES DELICES",
    rome: "D1102",
    siret: "84200004400011",
    address: {
      city: "PARIS 12",
      postcode: "75012",
      departmentCode: "75",
      streetNumberAndAddress: "39 RUE LOUIS BRAILLE",
    },
    website: "",
    nafLabel: "Fabrication de pain et de pâtisserie fraîche",
    position: { lat: 48.839834, lon: 2.404135 },
    romeLabel: "Boulangerie - viennoiserie",
    distance_m: 6525.51289164,
    contactMode: "IN_PERSON",
    appellations: [
      {
        appellationLabel: "Boulanger-pâtissier / Boulangère-pâtissière",
        appellationCode: "11574",
      },
    ],
    voluntaryToImmersion: true,
    additionalInformation: "",
    numberOfEmployeeRange: "1-2",
    locationId: "123",
    createdAt: new Date().toISOString(),
  },
  {
    naf: "1071C",
    establishmentScore: 0,
    name: "LE GRENIER A PAIN ITALIE",
    rome: "D1102",
    siret: "41525551200014",
    address: {
      city: "PARIS 13",
      postcode: "75013",
      departmentCode: "75",
      streetNumberAndAddress: "52 AV D'ITALIE",
    },
    website: "",
    nafLabel: "Fabrication de pain et de pâtisserie fraîche",
    position: { lat: 48.827715, lon: 2.356505 },
    romeLabel: "Boulangerie - viennoiserie",
    distance_m: 4379.7912238,
    contactMode: "EMAIL",
    customizedName: "EURL",
    appellations: [
      {
        appellationLabel: "Boulanger-pâtissier / Boulangère-pâtissière",
        appellationCode: "11574",
      },
    ],
    voluntaryToImmersion: true,
    additionalInformation: "",
    numberOfEmployeeRange: "",
    locationId: "123",
    updatedAt: new Date().toISOString(),
    createdAt: new Date().toISOString(),
  },
  {
    naf: "5610A",
    establishmentScore: 0,
    name: "B.O.U.L.O.M",
    rome: "D1102",
    siret: "42987567700016",
    address: {
      city: "PARIS 18",
      postcode: "75018",
      departmentCode: "75",
      streetNumberAndAddress: "181 RUE ORDENER",
    },
    website: "",
    nafLabel: "Restaurants et services de restauration mobile",
    position: { lat: 48.893627, lon: 2.33566 },
    romeLabel: "Boulangerie - viennoiserie",
    distance_m: 4029.35737599,
    contactMode: "EMAIL",
    appellations: [
      {
        appellationLabel: "Boulanger-pâtissier / Boulangère-pâtissière",
        appellationCode: "11574",
      },
    ],
    voluntaryToImmersion: true,
    additionalInformation: "",
    numberOfEmployeeRange: "20-49",
    locationId: "123",
    createdAt: new Date().toISOString(),
  },
  {
    naf: "4724Z",
    establishmentScore: 0,
    name: "MAISON KONTEH",
    rome: "D1102",
    siret: "81751896200011",
    address: {
      city: "PARIS 15",
      postcode: "75015",
      departmentCode: "75",
      streetNumberAndAddress: "141 RUE DE LA CROIX NIVERT",
    },
    website: "",
    nafLabel:
      "Commerce de détail de pain, pâtisserie et confiserie en magasin spécialisé",
    position: { lat: 48.841252, lon: 2.292138 },
    romeLabel: "Boulangerie - viennoiserie",
    distance_m: 2835.6388695,
    contactMode: "PHONE",
    customizedName: "SAS",
    appellations: [
      {
        appellationLabel: "Boulanger-pâtissier / Boulangère-pâtissière",
        appellationCode: "11574",
      },
    ],
    voluntaryToImmersion: true,
    additionalInformation: "",
    numberOfEmployeeRange: "6-9",
    locationId: "123",
    createdAt: new Date().toISOString(),
  },
  {
    naf: "1071C",
    establishmentScore: 0,
    name: "BOULANGERIE BAC",
    rome: "D1102",
    siret: "81320286800010",
    address: {
      city: "PARIS 13",
      postcode: "75013",
      departmentCode: "75",
      streetNumberAndAddress: "2 RUE DE LA BUTTE AUX CAILLES",
    },
    website: "",
    nafLabel: "Fabrication de pain et de pâtisserie fraîche",
    position: { lat: 48.828149, lon: 2.352437 },
    romeLabel: "Boulangerie - viennoiserie",
    distance_m: 4164.25129854,
    contactMode: "EMAIL",
    appellations: [
      {
        appellationLabel: "Boulanger / Boulangère",
        appellationCode: "11573",
      },
    ],
    voluntaryToImmersion: true,
    additionalInformation: "",
    numberOfEmployeeRange: "10-19",
    locationId: "123",
    updatedAt: new Date().toISOString(),
    createdAt: new Date().toISOString(),
  },
  {
    naf: "6820B",
    establishmentScore: 0,
    name: "LE GRENIER A PAIN ABBESSES",
    rome: "D1102",
    siret: "45216931100019",
    address: {
      city: "PARIS 18",
      postcode: "75018",
      departmentCode: "75",
      streetNumberAndAddress: "38 RUE DES ABBESSES",
    },
    website: "",
    nafLabel: "Location et exploitation de biens immobiliers propres ou loués",
    position: { lat: 48.885285, lon: 2.336676 },
    romeLabel: "Boulangerie - viennoiserie",
    distance_m: 3178.9096891,
    contactMode: "EMAIL",
    customizedName: "EURL",
    appellations: [
      {
        appellationLabel: "Boulanger-pâtissier / Boulangère-pâtissière",
        appellationCode: "11574",
      },
    ],
    voluntaryToImmersion: true,
    additionalInformation: "",
    numberOfEmployeeRange: "",
    locationId: "123",
    updatedAt: new Date().toISOString(),
    createdAt: new Date().toISOString(),
  },
];
